<template>
  <section>
    <VToolbar
      class="pt-2 pb-2"
      color="primary"
      flat
      dense
      dark
    >
      <VToolbarTitle id="toolbarTitle">
        {{ pageTitle }}
      </VToolbarTitle>
    </VToolbar>

    <div v-if="this.$store.state.receipt !== null">
      <section id="main">
        <div
          v-if="this.$store.state.receipt.status === 'success'"
          id="content"
        >
          <VCard
            v-for="i in this.$store.state.receipt.data"
            :key="i.id"
          >
            <VList two-line>
              <VListTile>
                <VListTileContent>
                  <VListTileTitle class="textMed">
                    {{ i.data.month }}
                  </VListTileTitle>
                  <VListTileSubTitle class="textSmall textBold">
                    {{ i.data.session }}
                  </VListTileSubTitle>
                </VListTileContent>
                <VListTileAction>
                  <VListTileActionText class="textBold">
                    {{ i.data.date }}
                  </VListTileActionText>
                  <VBtn
                    flat
                    color="secondary"
                    icon
                    @click="view(i.id)"
                  >
                    <VIcon>
                      open_in_new
                    </VIcon>
                  </VBtn>
                </VListTileAction>
              </VListTile>
            </VList>
          </VCard>
        </div>
        <div v-if="this.$store.state.receipt.status === 'invalid'">
          <NoResult />
        </div>
      </section>
    </div>
    <div
      v-else
      id="loading"
    >
      <Loading />
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import api from '../../configuration/api';
import Loading from '../../components/Loading.vue';
import NoResult from '../../components/NoResult.vue';

export default {
  components: {
    Loading,
    NoResult,
  },
  data: () => ({
    pageTitle: 'receipt',
    data: '',
  }),

  beforeCreate() {
    axios.get(`${api}/receipt/${this.$store.state.studentInfo.admNo}`)
      .then((res) => {
        this.data = res.data;
        this.$store.commit('setReceipt', this.data);
      })
      .catch(() => {
        this.retry();
      });
  },

  methods: {
    view(id) {
      this.$router.push(`${this.$route.path}/${id}`);
    },
    retry() {
      setTimeout(() => {
        axios.get(`${api}/receipt/${this.$store.state.studentInfo.admNo}`)
          .then((res) => {
            this.data = res.data;
            this.$store.commit('setReceipt', this.data);
          })
          .catch(() => {
            setTimeout(() => {
              this.retry();
            }, 5000);
          });
      }, 2500);
    },
  },

};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/text';
@import '../../assets/stylus/receipt';
</style>
